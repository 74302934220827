import {
    ICardTransactionsFullSearchPayload,
    ISupervisorApprovalsFullSearchPayload,
    ITicketsFullSearchPayload,
    IVouchersFullSearchPayload,
    IFinancialPerformanceFullSearchPayload
} from 'api/models/reports';

import { ORDER_BY_FINANCIAL_PERFORMANCE_COLUMN } from 'const';

export enum EXPORT_REPORT_TYPE {
  Tickets = 1,
  SupervisorApprovals = 2,
  CardTransactions = 3,
  Vouchers = 4,
  PlayerStatement = 5,
  InternalEmpUsersAudit = 6,
  FinancialPerformance = 7,
  Users = 8,
}

export interface ICardTransactionsReportExportPayload
  extends ICardTransactionsFullSearchPayload {
  reportType: number;
}

export interface ISupervisorReportExportPayload
  extends ISupervisorApprovalsFullSearchPayload {
  reportType: number;
}

export interface IUserExportPayload {
    searchFilter: string;
    userTypes: number[];
    emailHash: string | null;
    languageId: number;
    toDate: string;
    activeColumns: string[];
    reportType: number;
}

export interface ITicketsReportExportPayload extends ITicketsFullSearchPayload {
  reportType: number;
}

export interface IVouchersReportExportPayload
  extends IVouchersFullSearchPayload {
  reportType: number;
}
export interface IFinancialPerformanceExportPayload
  extends IFinancialPerformanceFullSearchPayload {
  orderByColumn: ORDER_BY_FINANCIAL_PERFORMANCE_COLUMN;
  reportType: number;
  isAscendingOrder: boolean;
}

export interface IReportsExportModel {
  jwtToken: string;
  reportId: string;
}

export interface IPlayerStatementExportPayload {
  fromDate: Date | string;
  toDate: Date | string;
  loyaltyId: string;
  reportType: EXPORT_REPORT_TYPE;
  languageId?: number;
}
