import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        width: '100%',
        [theme.breakpoints.down('sm')]: {
            display: 'block',
            minHeight: '100%'
        }
    },
    header: {
        padding: theme.spacing(6, 6, 3),

        [theme.breakpoints.down('lg')]: {
            padding: theme.spacing(6, 2, 2),
        },
        [theme.breakpoints.down('sm')]: {
            padding: theme.spacing(2),
        }
    },
    titleWrap: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    title: {
        margin: 0,
        fontSize: '1.5rem',
        fontWeight: 500,
        width: '100%'
    },
    tableWrap: {
        position: 'relative',
        display: 'flex',
        alignSelf: 'normal',
        flexDirection: 'column',
        flexGrow: 1,
        [theme.breakpoints.down('sm')]: {
            marginTop: 0
        }
    },
    table: {
        tableLayout: 'fixed',
        border: `1px solid ${theme.palette.grey[300]}`,
        borderRadius: '4px',
        '& thead': {
            [theme.breakpoints.down('sm')]: {
                display: 'none'
            }
        },
        '& tr': {
            [theme.breakpoints.down('sm')]: {
                display: 'flex',
                flexDirection: 'row',
                padding: theme.spacing(1, 2),
                borderBottom: '1px solid #e0e0e0',
                alignItems: 'center',
                justifyContent: 'space-between'
            },
            '&:last-child': {
                [theme.breakpoints.down('sm')]: {
                    borderBottom: 'none'
                }
            }
        },
        '& td': {
            [theme.breakpoints.down('sm')]: {
                padding: 0,
                borderBottom: 'none'
            }
        },
    },
    headerCell: {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        fontWeight: 400,
        fontSize: '0.750rem'
    },
    cell: {
        '& p': {
            margin: 0,
            wordBreak: 'break-word',
            fontWeight: 700,
        }
    },
    button: {
        padding: 0,
        textAlign: 'right',
        minWidth: 'auto',
        minHeight: 'auto',
        color: theme.palette.primary.main,
    },
    alertWrapper: {
        marginBottom: theme.spacing(2),
        width: '100%'
    }
}));

export default useStyles;
