import BaseApi from 'api/services/_BaseApi';
import { EmpEndpoint } from 'api/Endpoint';
import {
    IReportingApiBaseRequest,
    IPointsOfSaleModel,
    IFinancialPerformanceFullSearchPayload,
    IFinancialPerformanceSearchResponse,
} from 'api/models/reports';

import {
    IFinancialPerformanceExportPayload,
    IReportsExportModel,
} from 'api/models/reportsExport';

import { handleCookieToPayload } from 'utils/cookieMethods';

export default class FinancialPerformanceReportApi extends BaseApi {
    GetCashiersList(payload: IReportingApiBaseRequest) {
        return this.QueryEmpBff<IPointsOfSaleModel>(
            handleCookieToPayload(payload, 'showTestAccounts', 'IncludeTest'),
            EmpEndpoint.ReportFilters.GetEntities,
            this.QueryMethods.POST
        );
    }

    GetKiosksList(payload: IReportingApiBaseRequest) {
        return this.QueryEmpBff<IPointsOfSaleModel>(
            handleCookieToPayload(payload, 'showTestAccounts', 'IncludeTest'),
            EmpEndpoint.NetworkManagement.GetBetshops,
            this.QueryMethods.POST
        );
    }

    GetFinancialPerformancePage(payload: IFinancialPerformanceFullSearchPayload) {
        return this.QueryEmpBff<IFinancialPerformanceSearchResponse>(
            handleCookieToPayload(payload, 'showTestAccounts', 'IncludeTest'),
            EmpEndpoint.FinancialPerformanceReport.Search,
            this.QueryMethods.POST
        );
    }

    GetExport(payload: IFinancialPerformanceExportPayload) {
        return this.QueryEmpBff<IReportsExportModel>(
            handleCookieToPayload(payload, 'showTestAccounts', 'IncludeTest'),
            EmpEndpoint.FinancialPerformanceReportExport.GetExport,
            this.QueryMethods.POST
        );
    }
}
