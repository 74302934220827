import * as React from 'react';
import clsx from 'clsx';
import Api from 'api/Api';

import { Logout } from 'index';

import { Avatar, Button, ListItemText, Menu, MenuItem, MenuList, Theme } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import RefreshIcon from '@mui/icons-material/Refresh';
import FiberPinIcon from '@mui/icons-material/FiberPin';

import MenuLanguages from './MenuLanguages';

import { GlobalContextModel } from 'api/models/general';

import { GlobalContext } from 'context/globalContext';

import { throwNetworkError } from 'utils/requestCancelation';
import { USER_TYPES } from 'api/models/user';

const { useState, useContext } = React;

const useStyles = makeStyles((theme: Theme) => ({
    wrap: {
        overflow: 'hidden'
    },
    menu: {
        minWidth: 200,
        outline: 'none'
    },
    menuAvatar: {
        marginLeft: theme.spacing(1.5),
    },
    avatar: {
        width: theme.spacing(4),
        height: theme.spacing(4),
        marginRight: theme.spacing(2),
        borderRadius: '50%',
        backgroundColor: theme.palette.primary.main
    },
    button: {
        width: '100%',
        padding: `${theme.spacing(1)} 0`,
        overflow: 'hidden',
        '&:hover': {
            backgroundColor: '#fef5eb',
            '& span': {
                color: theme.palette.primary.main
            }
        },
    },
    user: {
        display: 'flex',
        flexDirection: 'column',
        textTransform: 'none',
        textAlign: 'left'
    },
    userText: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        maxWidth: '175px',
    },
    menuItemAction: {
        minHeight: theme.spacing(6),
        padding: 0,
        '& svg': {
            transition: 'none'
        },
        '&:hover': {
            '& svg': {
                fill: theme.palette.primary.main
            },
            '& span': {
                color: theme.palette.primary.main
            }
        }
    },
    userActionButton: {
        display: 'flex',
        alignItems: 'center',
        textDecoration: 'none',
        color: 'inherit'
    },
    logoutForm: {
        display: 'flex',
        width: '100%',
        height: '100%'
    },
    logoutButton: {
        width: '100%',
        padding: theme.spacing(0, 2),
        alignItems: 'center',
        justifyContent: 'flex-start',
        textTransform: 'none',
        '&:hover': {
            backgroundColor: 'rgba(0, 0, 0, 0)'
        },
    },
    userActionIcon: {
        marginRight: theme.spacing(2.5)
    },
    userActionText: {
        textAlign: 'left'
    }
}));

const MenuUser = () => {
    const classes = useStyles({});
    const { globalSettings, translations }: GlobalContextModel = useContext(GlobalContext);

    const userFullName = globalSettings.user?.FullName;
    const userRole = globalSettings.user?.RoleName;
    const isInternalUser = globalSettings.user?.IsInternal;
    const isSupervisor = globalSettings.user?.UserTypes.includes(USER_TYPES.supervisor);

    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

    const handleOpenMenu = event => {
        setAnchorEl(event.currentTarget);
    };

    const onClose = () => {
        setAnchorEl(null);
    };

    const handleChangePassword = () => {
        Api.General.GetChangePasswordUri().then(res => {
            document.location.href = res.changePasswordUri;
        }).catch(throwNetworkError);
    };

    const handleChangeSupervisorPin = () => {
        Api.General.GetChangeSupervisorPinUri().then(res => {
            document.location.href = res.changeSupervisorPinUri;
        }).catch(throwNetworkError);
    };

    const handleLogout = () => {
        Api.General.GetToken('DELETE');
        Logout.postMessage('Logout');
    };

    return (
        <div className={classes.wrap}>
            <Button id="user-profile-button" className={classes.button} aria-controls="simple-menu" aria-haspopup="true" onClick={handleOpenMenu}>
                <Avatar
                    className={clsx(classes.avatar, classes.menuAvatar)}
                >
                    {userFullName?.split(' ').map(word => word.slice(0, 1).toUpperCase()).join('')}
                </Avatar>
                <ListItemText className={classes.user}>
                    <div className={classes.userText}><b>{userFullName}</b></div>
                    <div>{userRole}</div>
                </ListItemText>
            </Button>
            <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={onClose}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
            >
                <MenuList className={classes.menu}>
                    <MenuLanguages closeParentMenu={onClose} />
                    {!isInternalUser &&
                        <MenuItem className={classes.menuItemAction}>
                            <Button className={classes.logoutButton} onClick={handleChangePassword}>
                                <RefreshIcon className={classes.userActionIcon} />
                                <ListItemText className={classes.userActionText} primary={translations['users-actions-change-password']} />
                            </Button>
                        </MenuItem>}
                    {isSupervisor &&
                        <MenuItem className={classes.menuItemAction}>
                            <Button className={classes.logoutButton} onClick={handleChangeSupervisorPin}>
                                <FiberPinIcon className={classes.userActionIcon} />
                                <ListItemText className={classes.userActionText} primary={translations['users-actions-change-supervisor-pin']} />
                            </Button>
                        </MenuItem>
                    }
                    <MenuItem className={classes.menuItemAction}>
                        <Button className={classes.logoutButton} onClick={handleLogout} id="logout-button">
                            <ExitToAppIcon className={classes.userActionIcon} />
                            <ListItemText className={classes.userActionText} primary={translations['gen-logout']} />
                        </Button>
                    </MenuItem>
                </MenuList>
            </Menu>
        </div>
    );
};

export default MenuUser;
