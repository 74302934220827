import React from 'react';
import clsx from 'clsx';

import { formatNumberToCurrencyString, numberStyles } from 'utils/formatNumber';

import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { CircularProgress, Typography } from '@mui/material';

import Aggregations from 'components/Aggregations/Aggregations';

import { GlobalContextModel } from 'api/models/general';
import { GlobalContext } from 'context/globalContext';

import {
    ITicketsFullAggregationsModel
} from 'api/models/reports';

const useStyles = makeStyles((theme: Theme) => ({
    wrap: {
        position: 'relative',
        minHeight: '155px',
        marginBottom: theme.spacing(3),
        display: 'grid',
        gridTemplateColumns: 'repeat(3, 1fr)',
        borderRadius: 4,
        border: `1px solid ${theme.palette.divider}`,
        backgroundColor: '#F8F9FA',

        [theme.breakpoints.down('sm')]: {
            display: 'block',
        },
    },
    wrapLoading: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    wide: {
        display: 'flex',
        gridColumn: 'span 3',
    },
    wideItem: {
        display: 'flex',
        flex: 'auto',
        flexDirection: 'column',
        padding: theme.spacing(2, 3),
        [theme.breakpoints.down('sm')]: {
            padding: theme.spacing(0.5, 1),
        }
    },
    alignItemsLeft: {
        alignItems: 'flex-start',
    },
    alignItemsCenter: {
        alignItems: 'center',
    },
    alignItemsRight: {
        alignItems: 'flex-end',
    },
    title: {
        fontSize: '10px',
        textTransform: 'uppercase',
        fontWeight: 500,
    },
    value: {
        fontSize: '16px',
        [theme.breakpoints.down('sm')]: {
            fontSize: '14px',
        }
    },
    bold: {
        fontWeight: 'bold',
    },
}));

const TicketsAggregations = ({
    aggregationsData,
    loadingData,
}: {
    aggregationsData: ITicketsFullAggregationsModel;
    loadingData: boolean;
}) => {
    const {
        soldTicketsQuantity,
        soldTotalValue,
        wonTicketsQuantity,
        wonTotalValue,
        lostTicketsQuantity,
        lostTotalValue,
        openTicketsQuantity,
        openTotalValue,
        drawTicketsQuantity,
        drawTotalValue,
        cashoutTicketsQuantity,
        cashoutTotalValue,
        canceledTicketsQuantity,
        canceledTotalValue,
        voidedTicketsQuantity,
        voidedTotalValue,
        handle,
        winnings,
        grossWin,
        unpaidTicketsQuantity,
        unpaidTotalValue,
    } = aggregationsData;

    const classes = useStyles({});
    const { globalSettings, translations }: GlobalContextModel = React.useContext(GlobalContext);

    const data = [
        { title: translations['emp-reports-ticket-sold'], value: soldTotalValue, count: soldTicketsQuantity, highlight: false, valueInRight: true },
        { title: translations['emp-reports-ticket-won'], value: wonTotalValue, count: wonTicketsQuantity, highlight: false, valueInRight: true },
        { title: translations['emp-reports-ticket-lost'], value: lostTotalValue, count: lostTicketsQuantity, highlight: false, valueInRight: true },
        { title: translations['emp-reports-ticket-open'], value: openTotalValue, count: openTicketsQuantity, highlight: false, valueInRight: true },
        { title: translations['emp-reports-ticket-draw'], value: drawTotalValue, count: drawTicketsQuantity, highlight: false, valueInRight: true },
        { title: translations['emp-reports-ticket-cashed-out'], value: cashoutTotalValue, count: cashoutTicketsQuantity, highlight: false, valueInRight: true },
        { title: translations['emp-reports-ticket-canceled'], value: canceledTotalValue, count: canceledTicketsQuantity, highlight: false, valueInRight: true },
        { title: translations['emp-reports-ticket-voided'], value: voidedTotalValue, count: voidedTicketsQuantity, highlight: false, valueInRight: true },
        { title: translations['emp-reports-ticket-unpaid'], value: unpaidTotalValue, count: unpaidTicketsQuantity, highlight: true, valueInRight: true },
    ];

    const dataWide = [
        { title: translations['emp-reports-ticket-handle'], value: handle, name: 'handle', align: 'Left' },
        { title: translations['emp-reports-ticket-winnings'], value: winnings, name: 'winnings', align: 'Center' },
        { title: translations['emp-reports-ticket-gross-win'], value: grossWin, name: 'grossWin', align: 'Right' },
    ];

    return (
        <div className={clsx(classes.wrap, loadingData && classes.wrapLoading)} data-a="ticket-aggregations-wrap">
            {loadingData
                ? <CircularProgress color="inherit" data-a="aggregations-spinner" />
                : (
                    <>
                        {
                            data.map((item, index) => (
                                <Aggregations
                                    key={index}
                                    title={item.title}
                                    value={item.value}
                                    count={item.count}
                                    valueInRight={item.valueInRight}
                                    highlight={item.highlight}
                                />
                            ))
                        }
                        <div className={classes.wide} data-a="aggregation-wide-section">
                            {
                                dataWide.map((item, index) => (
                                    <div
                                        key={index}
                                        className={clsx(classes.wideItem, classes[`alignItems${item.align}`])}
                                        data-a={`aggregation-${item.name}-section`}
                                    >
                                        <Typography className={classes.title} data-a={`aggregation-${item.name}-title`}>{item.title}</Typography>
                                        <Typography className={clsx(classes.value, classes.bold)} data-a={`aggregation-${item.name}-value`}>
                                            {formatNumberToCurrencyString(
                                                item.value,
                                                numberStyles(globalSettings?.settings?.Currency),
                                                globalSettings?.settings?.CurrencySign,
                                                globalSettings?.settings?.CurrencyFormat
                                            )}
                                        </Typography>
                                    </div>
                                ))
                            }
                        </div>
                    </>)
            }
        </div>
    );
};

export default TicketsAggregations;
