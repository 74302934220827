import React from 'react';
import clsx from 'clsx';

import { Theme } from '@mui/material/styles';

import makeStyles from '@mui/styles/makeStyles';

import { GlobalContextModel } from 'api/models/general';
import { GlobalContext } from 'context/globalContext';

import { IResource } from 'api/models/resource';

import { Checkbox } from '@mui/material';

import withStyles from '@mui/styles/withStyles';

import defaulTheme from 'theme';

import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';

import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

import { transitionDuration } from 'const';

import Api from 'api/Api';

import { findNestedItem } from '../../../business';
import { cancelRequests, throwNetworkError } from 'utils/requestCancelation';

const Accordion = withStyles({
    root: {
        width: '100%',
        border: '1px solid rgba(0, 0, 0, .125)',
        boxShadow: 'none',
        '&:not(:last-of-type)': { borderBottom: 0 },
        '&:before': { display: 'none' },
        '&$expanded': { margin: 'auto' },
        '& .MuiAccordionDetails-root .MuiAccordion-root': {
            borderTop: '1px solid rgba(0, 0, 0, .125)'
        },

        [defaulTheme.breakpoints.down('sm')]: {
            borderLeft: 'none',
            borderRight: 'none',
        },
    },
    expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles((theme: Theme) => ({
    root: {
        minHeight: '60px',
        padding: theme.spacing(0, 2),
        '&$expanded': {
            minHeight: '60px',
            padding: theme.spacing(0, 2),
        }
    },
    content: {
        margin: '0',
        '&$expanded': {
            margin: '0',
            '& .arrowIcon': {
                transform: 'rotate(90deg)'
            }
        },
    },
    expanded: {
    },
}))(MuiAccordionSummary);

const AccordionDetails = withStyles((theme: Theme) => ({
    root: {
        flexDirection: 'column',
        padding: 0,
        '& .MuiAccordion-root': {
            border: 'none',
            borderBottom: '1px solid rgba(0, 0, 0, .125)',
            '& .rowSummary': {
                paddingLeft: theme.spacing(6),
            },
            '& .rowBody': {
                paddingLeft: theme.spacing(8 + 6),
            },
            '&:last-child': {
                borderBottom: 'none'
            },
        }
    }
}))(MuiAccordionDetails);

const useStyles = makeStyles((theme: Theme) => ({
    head: {
        display: 'flex',
        alignItems: 'center',
        minHeight: '60px',
        marginTop: theme.spacing(2),
        padding: theme.spacing(1.5, 2, 1.5, 6),
        border: '1px solid rgba(0, 0, 0, .125)',
        borderBottom: 'none',

        [theme.breakpoints.down('sm')]: {
            display: 'none'
        },
    },
    row: {
        display: 'flex',
        width: '100%',
        alignItems: 'center',
    },
    rowBody: {
        padding: theme.spacing(1, 2, 1, 8),
        borderBottom: '1px solid rgba(0, 0, 0, .125)',
        cursor: 'pointer',
        '&:first-child': {
            borderTop: '1px solid rgba(0, 0, 0, .125)',
        },
        '&:last-child': {
            borderBottom: 'none',
        }
    },
    rowError: {
        background: theme.palette.error.light
    },
    nameCell: {
        flexGrow: 1
    },
    accessCell: {
        minWidth: '60px',
        textAlign: 'center',

        [theme.breakpoints.down('sm')]: {
            minWidth: '42px'
        },
    },
    boldCell: {
        fontWeight: 500
    },
    arrowIcon: {
        marginRight: theme.spacing(2),
        fontSize: '1rem',
        transitionProperty: 'transform',
        transitionDuration
    },
    error: {
        margin: '8px 14px 0',
        padding: 0,
        fontSize: '0.75rem',
        color: theme.palette.error.main
    }
}));

const apiRequests = {
    roleDetails: null,
    resourceList: null
};

const RolePermissions = ({
    hideInternal = false,
    handlePermissions,
    readOnly = false,
    requiredError,
    roleId,
    handleRoleDetailsData
}) => {
    const classes = useStyles({});
    const { translations }: GlobalContextModel = React.useContext(GlobalContext);
    const [loadedPermissions, setLoadedPermissions] = React.useState(false);

    const [permissionsList, setPermissionsList] = React.useState<Array<IResource>>([]);

    React.useEffect(() => {
        apiRequests.resourceList = Api.Resource.GetList();

        if (roleId) {
            apiRequests.roleDetails = Api.Role.Details({ id: roleId });
            Promise.all([
                apiRequests.roleDetails,
                apiRequests.resourceList
            ]).then(([roleDetailsResponse, resourceListResponse]) => {
                if (roleDetailsResponse && resourceListResponse) {
                    setLoadedPermissions(true);
                    handleRoleDetailsData(roleDetailsResponse);
                    buildTree(resourceListResponse, roleDetailsResponse.scopes);
                }
            }).catch(
                error => throwNetworkError(error)
            );
        } else {
            apiRequests.resourceList.then(res => {
                if (res) {
                    setLoadedPermissions(true);
                    buildTree(res, []);
                    //setPermissionsList(res);
                }
            }).catch(
                error => throwNetworkError(error)
            );
        }

        return () => cancelRequests(apiRequests);
    }, []);

    React.useEffect(() => {
        onHandlePermissions(permissionsList);
    }, [hideInternal]);

    const addParametersToTree = (item, parentName, rolePermissions) => {
        if (rolePermissions?.length) {
            if (item.children.length) {
                item.checked = allChildrenChecked(item.children, rolePermissions);
                item.expanded = item.children.some(item => rolePermissions.includes(item.id));
            } else {
                item.checked = rolePermissions.includes(item.id);
            }

        } else {
            item.checked = false;
            item.expanded = false;
        }

        item.error && console.error(`Wrong Keykloac configuration in ${item.name} resourse`);

        item.parentName = parentName;
        item.children.length && item.children.forEach(child => addParametersToTree(child, item.name, rolePermissions));

        return item;
    };

    const allChildrenChecked = (item, rolePermissions) => {
        return item.every(item => {
            if (item.children.length) {
                return item.children.every(child => {
                    if (hideInternal && child.internal) {
                        return true;
                    } else {
                        return rolePermissions.includes(child.id);
                    }
                });
            } else {
                if (hideInternal && item.internal) {
                    return true;
                } else {
                    return rolePermissions.includes(item.id);
                }
            }
        });
    };

    const buildTree = (data: Array<IResource>, rolePermissions: Array<string>) => {
        const dataWithAddedParameters = data.map(perm => addParametersToTree(perm, 'root', rolePermissions));

        setPermissionsList(dataWithAddedParameters);
    };

    const handleExpanded = (name: string) => (event, newExpanded) => {
        const newPermissionList = [...permissionsList];
        const expandedItem = findNestedItem(newPermissionList, name);

        expandedItem.expanded = newExpanded;
        setPermissionsList(newPermissionList);
    };

    const checkExpanded = (name: string) => {
        return findNestedItem(permissionsList, name).expanded;
    };

    const handleDependencyPermission = (list, permission) => {
        const itemsFromDependecies = [];

        permission.dependencies.forEach(dependency => itemsFromDependecies.push(findNestedItem(list, dependency)));
        itemsFromDependecies.forEach(itemFromDependecies => {
            const allDependency = [];

            buildDependencyArr(list, itemFromDependecies.name, allDependency);

            if (allDependency.length && !itemFromDependecies.checked) {
                itemFromDependecies.checked = allDependency.some(item => item.checked);
            }
        });
    };

    const buildDependencyArr = (list, name, resArr) => {
        name && list.forEach(item => {
            if (item.dependencies.some(dependency => dependency === name)) {
                resArr.push(item);
            }

            item.children.length && buildDependencyArr(item.children, name, resArr);
        });
    };

    const handleCheck = (permission: IResource) => {
        const newPermissionList = [...permissionsList];
        const clickedItem = findNestedItem(newPermissionList, permission.name);

        if (clickedItem.checked) {
            clearAllDependency(newPermissionList, permission.name);
        }

        clickedItem.checked = !clickedItem.checked;

        handleDependencyPermission(newPermissionList, permission);
        checkAllParent(newPermissionList, permission);
        onHandlePermissions(newPermissionList);
        setPermissionsList(newPermissionList);
    };

    const onHandlePermissions = (list) => {
        const allChecked = [];

        findAllChecked(list, allChecked, hideInternal);
        handlePermissions(allChecked);
    };

    const checkAllParent = (list, permission: IResource) => {
        const parentItem = findNestedItem(list, permission.parentName);

        parentItem.checked = parentItem.children.every(child => hideInternal && child.internal ? true : child.checked);
        parentItem.parentName !== 'root' && checkAllParent(list, parentItem);
    };

    const toggleAllChildrenCheck = (item, checked) => {
        item.checked = checked;

        if (item.children.length) {
            item.children.forEach(i => toggleAllChildrenCheck(i, checked));
        }
    };

    const handleParentCheck = (permission: IResource) => {
        const newPermissionList = [...permissionsList];
        const clickedItem = findNestedItem(newPermissionList, permission.name);

        toggleAllChildrenCheck(clickedItem, !clickedItem.checked);
        handleDependencyPermission(newPermissionList, permission);
        onHandlePermissions(newPermissionList);
        setPermissionsList(newPermissionList);
    };

    const findAllChecked = (list: Array<IResource>, resArr: Array<IResource>, hideInternal?: boolean) => {
        list.forEach(item => {
            item.checked && item.id && (!hideInternal || hideInternal && !item.internal) && resArr.push(item);

            item.children.length && findAllChecked(item.children, resArr, hideInternal);
        });
    };

    const clearAllDependency = (list, dependencyName) => {
        list.forEach(item => {
            if (item.dependencies.length && item.dependencies.some(dependency => dependency === dependencyName)) {
                item.checked = false;
            }

            item.children.length && clearAllDependency(item.children, dependencyName);
        });
    };

    const RenderAccordion = ({ permission }) => (
        (!hideInternal || hideInternal && !permission.internal) &&
            <Accordion
                classes={permission.error ? { root: classes.rowError } : {}}
                square elevation={0}
                expanded={checkExpanded(permission.name)}
                onChange={handleExpanded(permission.name)}
            >
                <AccordionSummary id={permission.id} >
                    <div className={clsx(classes.row, 'rowSummary')}
                        data-a="role-permissions-parent-permission"
                        data-a-internal={permission.internal}
                    >
                        <ArrowForwardIosIcon className={clsx(classes.arrowIcon, 'arrowIcon')} />
                        <div className={clsx(classes.row, 'row')}>
                            <span className={clsx(classes.nameCell, classes.boldCell)}>{translations[`permission-${permission.name}`]}</span>
                            <span className={classes.accessCell}>
                                {permission.error
                                    ? null
                                    : (
                                        <Checkbox
                                            color="primary"
                                            onClick={(event) => {
                                                event.stopPropagation();
                                                !readOnly && handleParentCheck(permission);
                                            }}
                                            checked={permission.checked}
                                            disabled={readOnly}
                                            data-a="role-permissions-parent-permission-checkbox"
                                        />
                                    )
                                }
                            </span>
                        </div>
                    </div>
                </AccordionSummary>
                <AccordionDetails>
                    {
                        permission.children.map(permissionChild => {
                            return permissionChild.children.length
                                ? <RenderAccordion key={permissionChild.name} permission={permissionChild} />
                                : ((!hideInternal || hideInternal && !permissionChild.internal) &&
                                    <div
                                        data-a="role-permissions-child-permission"
                                        data-a-internal={permissionChild.internal}
                                        key={permissionChild.name}
                                    >
                                        <div
                                            className={clsx(classes.row, classes.rowBody, 'rowBody')}
                                            onClick={(event) => {
                                                event.stopPropagation();
                                                !readOnly && handleCheck(permissionChild);
                                            }}
                                        >
                                            <span className={clsx(classes.nameCell)}>{translations[`permission-${permissionChild.name}`]}</span>
                                            <span className={classes.accessCell}>
                                                <Checkbox
                                                    color="primary"
                                                    checked={permissionChild.checked}
                                                    disabled={readOnly}
                                                    data-a="role-permissions-child-permission-checkbox"
                                                />
                                            </span>
                                        </div>
                                    </div>);
                        })
                    }
                </AccordionDetails>
            </Accordion>
    );

    return (
        loadedPermissions &&
            <div>
                <div className={classes.head}>
                    <span className={classes.nameCell}>{translations['hm-column-name']}</span>
                    <span className={classes.accessCell}>{translations['role-access']}</span>
                </div>
                {permissionsList.map((permission) =>
                    <RenderAccordion key={permission.name} permission={permission} />
                )}
                {requiredError &&
                    <p className={classes.error}>{translations['users-valid-msg-empty-field']}</p>
                }
            </div>
    );
};

export default React.memo(RolePermissions);
