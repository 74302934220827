import React, {
    useEffect,
    useState,
} from 'react';
import ReactDOM from 'react-dom';

import { BrowserRouter, withRouter } from 'react-router-dom';
import Root from 'components/Root';

import CustomPrompt from 'components/CustomPrompt/CustomPrompt';

import { cancelRequests } from 'utils/requestCancelation';
import { ticketsReportRoute } from 'const';
import { menuItems } from 'data';

import { EposContextProvider } from 'context/epos';
import { KpiContextProvider } from 'context/kpi';
import { ExportToExcelProvider } from 'context/exportToExcel';
import { GlobalContextProvider } from 'context/globalContext';

import { aggregationsRequests } from 'pages/Reports/Tickets/TicketsReport';

interface LocationState {
    pathFrom?: string;
  }

function BaseLogger(props) {
    const [currentPath, setCurrentPath] = useState(props.history.location.pathname);

    useEffect(() => {
        props.history.listen((location) => {
            const { state } = location as { state: LocationState };
            const currentItem = menuItems.find(item => item.path === location.pathname);

            if (currentPath !== location.pathname && currentItem?.hasOwnProperty('loadData')) {
                currentItem.loadData();
            }

            if (state?.pathFrom as string === ticketsReportRoute) {
                cancelRequests(aggregationsRequests);
            }

            setCurrentPath(location.pathname);
        });
    }, []);

    return (null);
}

const HistoryLogger = withRouter(BaseLogger);

function App() {
    const userConfirmation = React.useCallback((message, callback) => {
        const node = document.getElementById('custom-prompt');

        const cleanUp = answer => {
            ReactDOM.unmountComponentAtNode(node);
            callback(answer);
        };

        ReactDOM.render(<CustomPrompt message={message} cleanUp={cleanUp} />, node);
    }, []);

    return (
        <GlobalContextProvider>
            <EposContextProvider>
                <KpiContextProvider>
                    <ExportToExcelProvider>
                        <BrowserRouter getUserConfirmation={userConfirmation}>
                            <HistoryLogger />
                            <Root />
                        </BrowserRouter>
                    </ExportToExcelProvider>
                </KpiContextProvider>
            </EposContextProvider>
        </GlobalContextProvider>
    );
}

export default App;
