import BaseApi from 'api/services/_BaseApi';
import { EmpEndpoint } from 'api/Endpoint';
import {
    IReportTicketModel,
    ITicketsFullSearchPayload,
    ITicketsFullSearchResponse,
    ITicketDetailsModel,
    ITicketsByIdPayload,
    IAutorizeReprintPayload,
    IRefreshTicketDetailsPayload,
    ITicketVoidPayload,
    ITicketsAggregationsModel,
    ITicketsUnpaidAggregationsModel,
} from 'api/models/reports';

import {
    ITicketsReportExportPayload,
    IReportsExportModel,
} from 'api/models/reportsExport';

import { handleCookieToPayload } from 'utils/cookieMethods';

export default class TicketsReportApi extends BaseApi {
    GetTicketsList(payload: ITicketsFullSearchPayload) {
        return this.QueryEmpBff<ITicketsFullSearchResponse>(handleCookieToPayload(payload, 'showTestAccounts', 'IncludeTest'), EmpEndpoint.TicketsReport.Search, this.QueryMethods.POST);
    }

    GetTicketsListById(payload: ITicketsByIdPayload) {
        return this.QueryEmpBff<Array<IReportTicketModel>>(handleCookieToPayload(payload, 'showTestAccounts', 'IncludeTest'), EmpEndpoint.TicketsReport.SearchByTicketId, this.QueryMethods.POST);
    }

    GetTicketsCount(payload: ITicketsFullSearchPayload) {
        return this.QueryEmpBff<number>(handleCookieToPayload(payload, 'showTestAccounts', 'IncludeTest'), EmpEndpoint.TicketsReport.Count, this.QueryMethods.POST);
    }

    GetTicketsDetails(payload: ITicketsByIdPayload) {
        return this.QueryEmpBff<ITicketDetailsModel>(handleCookieToPayload(payload, 'showTestAccounts', 'IncludeTest'), EmpEndpoint.TicketsReport.Details, this.QueryMethods.POST);
    }

    GetExport(payload: ITicketsReportExportPayload) {
        return this.QueryEmpBff<IReportsExportModel>(
            handleCookieToPayload(payload, 'showTestAccounts', 'IncludeTest'),
            EmpEndpoint.TicketsReportExport.GetExport,
            this.QueryMethods.POST
        );
    }

    AutorizeReprint(payload: IAutorizeReprintPayload) {
        return this.QueryEmpBff<boolean>(payload, EmpEndpoint.TicketsReport.AutorizeReprint, this.QueryMethods.PATCH);
    }

    TicketVoid(payload: ITicketVoidPayload) {
        return this.QueryEmpBff<boolean>(payload, EmpEndpoint.TicketsReport.TicketVoid, this.QueryMethods.PATCH);
    }

    RefreshDetails(payload: IRefreshTicketDetailsPayload) {
        return this.QueryEmpBff<ITicketDetailsModel>(handleCookieToPayload(payload, 'showTestAccounts', 'IncludeTest'), EmpEndpoint.TicketsReport.RefreshDetails, this.QueryMethods.POST);
    }

    GetAggregations(payload: ITicketsFullSearchPayload) {
        return this.QueryEmpBff<ITicketsAggregationsModel>(handleCookieToPayload(payload, 'showTestAccounts', 'IncludeTest'), EmpEndpoint.TicketsReport.Aggregations, this.QueryMethods.POST);
    }

    GetUnpaidAggregations(payload: ITicketsFullSearchPayload) {
        return this.QueryEmpBff<ITicketsUnpaidAggregationsModel>(handleCookieToPayload(payload, 'showTestAccounts', 'IncludeTest'), EmpEndpoint.TicketsReport.UnpaidAggregations, this.QueryMethods.POST);
    }
}
