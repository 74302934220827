import React from 'react';
import clsx from 'clsx';

import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { Typography } from '@mui/material';

import { GlobalContextModel } from 'api/models/general';
import { GlobalContext } from 'context/globalContext';

import { formatNumberToCurrencyString, numberStyles } from 'utils/formatNumber';

const useStyles = makeStyles((theme: Theme) => ({
    wrap: {
        padding: theme.spacing(2, 3),
        color: 'inherit',
        borderRight: `1px solid ${theme.palette.divider}`,
        borderBottom: `1px solid ${theme.palette.divider}`,

        '&:nth-child(3n)': {
            borderRight: 'none',
        },
        [theme.breakpoints.down('sm')]: {
            padding: theme.spacing(0.5, 1),
            borderRight: 'none',
            borderBottom: `1px solid ${theme.palette.divider}`,
            '&:last-child': {
                borderBottom: 'none',
            },
        }
    },
    itemWrap: {
        display: 'flex',
        alignItems: 'center',
    },
    spaceBetween: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    highlight: {
        color: theme.palette.primary.main,
    },
    title: {
        fontSize: '10px',
        textTransform: 'uppercase',
        fontWeight: 'bold',
    },
    value: {
        fontSize: '16px',
        [theme.breakpoints.down('sm')]: {
            fontSize: '14px',
        }
    },
    valueMedium: {
        fontSize: '14px',
    },
    valueSmall: {
        fontSize: '13px',
    },
    bold: {
        fontWeight: 'bold',
    },
}));

const Aggregations = ({
    title,
    value,
    count,
    valueInRight = false,
    highlight = false,
}: {
    title: string;
    value: number;
    count: number;
    valueInRight?: boolean;
    highlight?: boolean;
}) => {
    const classes = useStyles({});
    const { globalSettings }: GlobalContextModel = React.useContext(GlobalContext);

    const wrapDataA = `aggregation-wrap-${highlight ? 'highlight' : 'default'}`;
    const titleReplacedDashes = title.replace(/\s+/g, '-').toLowerCase();

    return (
        <div className={clsx(classes.wrap, highlight && classes.highlight)} data-a={wrapDataA}>
            {
                valueInRight
                    ? (
                        <div className={classes.spaceBetween}>
                            <div>
                                <Typography className={classes.title} data-a={`aggregation-${titleReplacedDashes}-title`}>{title}</Typography>
                                <span className={classes.valueSmall} data-a={`aggregation-${titleReplacedDashes}-count`}>{count}</span>
                            </div>
                            <div className={classes.itemWrap}>
                                <span className={clsx(classes.value, classes.bold)} data-a={`aggregation-${titleReplacedDashes}-value`}>{
                                    formatNumberToCurrencyString(
                                        value,
                                        numberStyles(globalSettings?.settings?.Currency),
                                        globalSettings?.settings?.CurrencySign,
                                        globalSettings?.settings?.CurrencyFormat
                                    )}
                                </span>
                            </div>
                        </div>
                    )
                    : (
                        <>
                            <Typography className={classes.title} data-a={`aggregation-${titleReplacedDashes}-title`}>{title}</Typography>
                            <div className={classes.spaceBetween}>
                                <span className={clsx(classes.value, classes.bold)} data-a={`aggregation-${titleReplacedDashes}-value`}>{
                                    formatNumberToCurrencyString(
                                        value,
                                        numberStyles(globalSettings?.settings?.Currency),
                                        globalSettings?.settings?.CurrencySign,
                                        globalSettings?.settings?.CurrencyFormat
                                    )}
                                </span>
                                <span className={classes.value} data-a={`aggregation-${titleReplacedDashes}-count`}>{count}</span>
                            </div>
                        </>)
            }
        </div>
    );
};

export default Aggregations;
